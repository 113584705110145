<template>
  <div class="highlight" v-if="data">
    <div class="highlight-inner">
      <SvgSprite
        class="highlight__picto"
        symbol="icons-communication"
        size="80"
      />

      <div class="highlight__content">
        <h4 class="highlight__content__title">
          {{ data.title }}
        </h4>
        <div class="highlight__content__text">
          {{ data.excerpt }}
        </div>
      </div>
    </div>
    <div class="label">Épinglé</div>
    <SvgSprite class="icon-arrow" symbol="ui-arrow-right" size="16 16" />
    <router-link :to="data.url" class="highlight__link"></router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Highlight',
  props: {
    data: Object,
  } as any, // eslint-disable-line
})
</script>

<style scoped lang="scss">
$p: (
  xxs: 15px,
  l: 20px,
);

.highlight {
  position: relative;
  margin: 0 auto 4.5rem;
  max-width: 55rem;
  min-height: 10.7rem;
  position: relative;
  background-color: $c-white;
  border: 1px solid $athens-gray;

  .icon-arrow {
    @include fluid(right, $p);

    position: absolute;
    bottom: 2rem;
    right: 0;
    stroke: $tangerine;
    transition: transform 0.3s;
  }

  &:hover {
    .icon-arrow {
      transform: translateX(3px);
    }
  }
}

.highlight-inner {
  display: flex;
  align-items: center;
  height: 100%;
}

.highlight__picto {
  margin: 3rem;
  width: 8rem;
  height: 8rem;
}

.highlight__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin: $spacing 6rem $spacing $spacing;
  // margin-left: $spacing * 2;
}

.highlight__content__title {
  margin-bottom: 1.2rem;
  color: $c-black;
}

.highlight__content__text {
  font-size: 1.2rem;
}

.highlight__link {
  @include get-all-space;
}

.label {
  @include fira-medium;

  position: absolute;
  top: -5px;
  left: 3rem;
  padding: 0.4rem 1rem;
  color: $c-white;
  background-color: $tangerine;
  font-size: 1rem;
  text-align: center;
}
</style>
