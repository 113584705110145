<template>
  <div class="projects" v-if="content && chromeData">
    <Hero :title="content.title" :picture="content.picture">
      <SelectCustom
        @select="filterByCategory"
        placeholder="Catégorie de projet"
        :data="chromeData.lists.projectCategories"
      />
      <transition name="fade">
        <SelectCustom
          v-if="!loadingTypes"
          @select="filterByType"
          placeholder="Type de projet"
          :data="typesList"
        />
      </transition>
    </Hero>

    <div class="actions">
      <BackBtn />

      <router-link :to="{ name: 'AddProject' }" class="add">
        <p>Ajouter un projet</p>
        <div class="bullet">
          <SvgSprite symbol="icons-plus" size="20" />
        </div>
      </router-link>
    </div>

    <div class="wrapper">
      <Highlight v-if="content.tipsPost" :data="content.tipsPost" />
    </div>

    <transition name="fade">
      <div class="wrapper" v-if="!loading">
        <div v-if="filteredList.length" class="project__content__grid">
          <router-link
            v-for="item in filteredList"
            :key="item.url"
            class="m-xs"
            :to="item.url"
          >
            <Section pageType="project" :data="item" />
          </router-link>
        </div>

        <p v-else>No content</p>
      </div>
    </transition>
  </div>
  <div v-else>
    <Error />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'
import { list } from '@/guides/endpoints/projects'

import BackBtn from '@/components/BackBtn.vue'
import Error from '@/components/Error.vue'
import Hero from '@/components/Hero.vue'
import Highlight from '@/components/Highlight.vue'
import Section from '@/components/inventory/Section.vue'
import SelectCustom from '@/components/SelectCustom.vue'

export default defineComponent({
  name: 'Projects',
  components: {
    BackBtn,
    Error,
    Hero,
    Highlight,
    Section,
    SelectCustom,
  },
  setup() {
    const chromeData = ref()
    const content = ref()
    const filteredList = ref()
    const loading = ref(false)
    const loadingTypes = ref(false)
    const filterIsCategory = ref()
    const filterIsType = ref()
    const typesList = ref()

    chrome().then(r => {
      chromeData.value = r.data
      console.log('chrome', r.data)

      typesList.value = r.data.lists.projectTypes
    })

    list().then(r => {
      content.value = r.data.content
      filteredList.value = content.value.items
    })

    const filterByCategory = (category: string) => {
      filterIsCategory.value = category
      loading.value = true
      loadingTypes.value = true

      if (filterIsCategory.value === 'all-projects') {
        setTimeout(() => {
          loading.value = false
          loadingTypes.value = false
        }, 250)

        filteredList.value = content.value.items
        typesList.value = chromeData.value.lists.projectTypes
      } else {
        filteredList.value = content.value.items.filter(function(card: any) {
          setTimeout(() => {
            loading.value = false
            loadingTypes.value = false
          }, 250)

          if (card.category && filterIsType.value) {
            return (
              card.category.value === category &&
              card.types[0].value === filterIsType.value
            )
          } else if (card.category) {
            return card.category.value === category
          }
        })

        typesList.value = chromeData.value.lists.projectTypes.filter(function(
          type: any
        ) {
          if (category === 'projet-dannee') {
            return type.slug.includes('projet-annee-')
          } else {
            return !type.slug.includes('projet-annee-')
          }
        })
      }
    }

    const filterByType = (type: string) => {
      filterIsType.value = type
      loading.value = true

      filteredList.value = content.value.items.filter(function(card: any) {
        setTimeout(() => {
          loading.value = false
        }, 500)

        if (card.category && filterIsCategory.value) {
          return (
            card.types[0].value === type &&
            card.category.value === filterIsCategory.value
          )
        } else if (card.types) {
          return card.types[0].value === type
        }
      })
    }

    return {
      chromeData,
      content,
      filterByCategory,
      filterByType,
      filteredList,
      loading,
      loadingTypes,
      filterIsCategory,
      filterIsType,
      typesList,
    }
  },
})
</script>

<style lang="scss" scoped>
.projects {
  ::v-deep {
    .wrapper {
      max-width: 745px;
    }

    .slot__inner {
      display: flex;
      justify-content: space-between;

      > div {
        width: calc(50% - 0.6rem);
      }
    }
  }
}

.add {
  position: fixed;
  z-index: 20;
  bottom: 30px;
  right: 30px;

  transition: opacity 0.3s;

  &:hover {
    opacity: 0.6;
  }

  .bullet {
    display: flex;
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    background: $regal-blue;
    border-radius: 50%;
  }

  p {
    display: none;
    font-size: 1.4rem;
    color: $tangerine;
    margin-right: 10px;
  }

  svg {
    fill: $c-white;
  }

  @include mq($until: s) {
    opacity: 0;
    animation: bubble 0.5s forwards;
    animation-delay: 0.3s;
  }
}

.project__content__grid {
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(1, 1fr);

  > * {
    margin: 0;
  }

  @include mq(s) {
    grid-template-columns: repeat(2, 1fr);
  }

  ::v-deep(.section) {
    margin-bottom: 0;
  }
}

@include mq(s) {
  .actions {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    margin-bottom: 30px;
    margin-top: -40px;

    .back-btn {
      position: static;
      margin: 0;
    }

    .add {
      z-index: 1;
      display: inline-flex;
      position: static;
      align-items: center;
    }

    .add p {
      display: block;
    }

    .add .bullet {
      width: 30px;
      height: 30px;

      svg {
        width: 13px;
        height: auto;
      }
    }
  }
}

// Keyframes

@keyframes bubble {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

// Transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
