
import { defineComponent, ref } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'
import { list } from '@/guides/endpoints/projects'

import BackBtn from '@/components/BackBtn.vue'
import Error from '@/components/Error.vue'
import Hero from '@/components/Hero.vue'
import Highlight from '@/components/Highlight.vue'
import Section from '@/components/inventory/Section.vue'
import SelectCustom from '@/components/SelectCustom.vue'

export default defineComponent({
  name: 'Projects',
  components: {
    BackBtn,
    Error,
    Hero,
    Highlight,
    Section,
    SelectCustom,
  },
  setup() {
    const chromeData = ref()
    const content = ref()
    const filteredList = ref()
    const loading = ref(false)
    const loadingTypes = ref(false)
    const filterIsCategory = ref()
    const filterIsType = ref()
    const typesList = ref()

    chrome().then(r => {
      chromeData.value = r.data
      console.log('chrome', r.data)

      typesList.value = r.data.lists.projectTypes
    })

    list().then(r => {
      content.value = r.data.content
      filteredList.value = content.value.items
    })

    const filterByCategory = (category: string) => {
      filterIsCategory.value = category
      loading.value = true
      loadingTypes.value = true

      if (filterIsCategory.value === 'all-projects') {
        setTimeout(() => {
          loading.value = false
          loadingTypes.value = false
        }, 250)

        filteredList.value = content.value.items
        typesList.value = chromeData.value.lists.projectTypes
      } else {
        filteredList.value = content.value.items.filter(function(card: any) {
          setTimeout(() => {
            loading.value = false
            loadingTypes.value = false
          }, 250)

          if (card.category && filterIsType.value) {
            return (
              card.category.value === category &&
              card.types[0].value === filterIsType.value
            )
          } else if (card.category) {
            return card.category.value === category
          }
        })

        typesList.value = chromeData.value.lists.projectTypes.filter(function(
          type: any
        ) {
          if (category === 'projet-dannee') {
            return type.slug.includes('projet-annee-')
          } else {
            return !type.slug.includes('projet-annee-')
          }
        })
      }
    }

    const filterByType = (type: string) => {
      filterIsType.value = type
      loading.value = true

      filteredList.value = content.value.items.filter(function(card: any) {
        setTimeout(() => {
          loading.value = false
        }, 500)

        if (card.category && filterIsCategory.value) {
          return (
            card.types[0].value === type &&
            card.category.value === filterIsCategory.value
          )
        } else if (card.types) {
          return card.types[0].value === type
        }
      })
    }

    return {
      chromeData,
      content,
      filterByCategory,
      filterByType,
      filteredList,
      loading,
      loadingTypes,
      filterIsCategory,
      filterIsType,
      typesList,
    }
  },
})
